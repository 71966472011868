import { GeneralErrorBoundary } from "#app/components/error-boundary.tsx";
import { ErrorList, Field, PasswordField } from "#app/components/forms.tsx";
import { StatusButton } from "#app/components/ui/status-button.tsx";
import { api } from "#app/utils/http.server.ts";
import { authenticator } from "#app/utils/auth.server.ts";
import { checkHoneypot } from "#app/utils/honeypot.server.ts";
import { useIsPending } from "#app/utils/misc.tsx";
import { destroyRedirectToHeader } from "#app/utils/redirect-cookie.server.ts";
import { redirectWithToast } from "#app/utils/toast.server.ts";
import {
  DecodeSchema,
  SuccessLoginSchema,
  UserSchema,
} from "#app/utils/validation/auth-validation.ts";
import { EmailSchema, PasswordSchema } from "#app/utils/validation/user-validation.ts";
import { getFormProps, getInputProps, useForm } from "@conform-to/react";
import { getZodConstraint, parseWithZod } from "@conform-to/zod";
import { ActionFunctionArgs, json, LoaderFunctionArgs, MetaFunction } from "@remix-run/node";
import { Form, Link, useActionData, useSearchParams } from "@remix-run/react";
import jwt from "jsonwebtoken";
import { HoneypotInputs } from "remix-utils/honeypot/react";
import { z } from "zod";
import { handleNewSession } from "./login.server";
import { getRedirectToUrl } from "./verify.server";

const LoginFormSchema = z.object({
  email: EmailSchema,
  password: PasswordSchema,
  redirectTo: z.string().optional(),
});

const destroyRedirectTo = { "set-cookie": destroyRedirectToHeader };
import ky from "ky";

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  checkHoneypot(formData);

  const submission = await parseWithZod(formData, {
    schema: (intent) =>
      LoginFormSchema.transform(async (d, ctx) => {
        if (intent !== null) return { ...d, user: null };

        const { redirectTo, ...payload } = d;

        type User = z.infer<typeof SuccessLoginSchema>; // Ambil tipe dari ErrorSchema
        const { data, meta } = await api.post("auth/login/v2", { json: payload }).json<User>();

        let exp = undefined;

        if (!data) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: meta.message ?? "Invalid username or password",
          });
          return z.NEVER;
        }

        if (data?.accessToken === null) {
          const payload = {
            email: data.email as string,
            phoneNumber: data.phoneNumber as string,
          };
          let { data: otp } = await api
            .post("auth/otp/send", {
              json: payload,
            })
            .json<{ data: { status: boolean; nextOtpAttempt: string } }>();

          if (!otp.status) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Error request otp",
            });
            return z.NEVER;
          }

          if (otp.status) {
            const verifyUrl = getRedirectToUrl({
              request,
              type: "onboarding",
              target: data.email as string,
              secondTarget: data.phoneNumber as string,
            });
            const redirectTo = new URL(verifyUrl.toString());
            throw await redirectWithToast(
              redirectTo.toString(),
              {
                title: "Error",
                description: "Please verify your account.",
                type: "error",
              },
              { headers: destroyRedirectTo }
            );
          }
        }
        const decodedToken = DecodeSchema.parse(jwt.decode(data?.accessToken as string));

        if (!decodedToken.exp) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid response from server",
          });
          return z.NEVER;
        }

        exp = new Date(decodedToken.exp * 1000);

        return { ...d, user: { ...data, exp } };
      }),
    async: true,
  });

  if (submission.status !== "success" || !submission.value.user) {
    return json(
      { result: submission.reply({ hideFields: ["password"] }) },
      { status: submission.status === "error" ? 400 : 200 }
    );
  }

  const { user, redirectTo } = submission.value;

  let parseUser = UserSchema.parse(user);

  if (!parseUser.photo) {
    parseUser.photo = "/static/images/logokedi.svg";
  }

  return handleNewSession({
    user: parseUser,
    request,
    redirectTo,
  });
}

export async function loader({ request }: LoaderFunctionArgs) {
  await authenticator.isAuthenticated(request, {
    successRedirect: "/",
  });
  return json({});
}

export default function LoginPage() {
  const actionData = useActionData<typeof action>();
  const isPending = useIsPending();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get("redirectTo");

  const [form, fields] = useForm({
    id: "login-form",
    constraint: getZodConstraint(LoginFormSchema),
    defaultValue: { redirectTo },
    lastResult: actionData?.result,
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: LoginFormSchema });
    },
    shouldRevalidate: "onBlur",
  });

  return (
    <div className="h-screen flex  items-center justify-center bg-[#9D21E6]">
      <div className="flex flex-col items-center max-h-[95vh] bg-white rounded-xl my-4  mx-4 px-8 py-10 max-w-lg w-full">
        <img
          src="/static/images/logo_login.png"
          alt="Logo Kelas Digital"
          className="max-w-[145px]  mx-auto"
        />

        <div className="mx-auto w-full max-w-lg">
          <Form method="POST" {...getFormProps(form)}>
            <HoneypotInputs />
            <Field
              labelProps={{ children: "Email" }}
              inputProps={{
                ...getInputProps(fields.email, { type: "email" }),
                autoFocus: true,
                className: "lowercase",
                autoComplete: "email",
              }}
              errors={fields.email.errors}
            />

            <PasswordField
              labelProps={{ children: "Password" }}
              inputProps={{
                ...getInputProps(fields.password, {
                  type: "password",
                }),
                placeholder: "password",
                autoComplete: "current-password",
              }}
              errors={fields.password.errors}
            />
            <div className="flex justify-end">
              <div>
                <Link to="/forgot-password" className="text-body-xs font-medium">
                  Forgot password?
                </Link>
              </div>
            </div>

            <input {...getInputProps(fields.redirectTo, { type: "hidden" })} />
            <ErrorList errors={form.errors} id={form.errorId} />

            <div className="flex items-center justify-between gap-6 pt-3">
              <StatusButton
                className="w-full bg-[#9D21E6] hover:bg-purple-700 text-white "
                status={isPending ? "pending" : (form.status ?? "idle")}
                type="submit"
                disabled={isPending}
              >
                Log in
              </StatusButton>
            </div>
          </Form>
          <ul className="mt-5 flex flex-col items-center gap-5 border-b-2 border-t-2 border-border py-3">
            <Form action="/auth/google" method="post" className="w-full ">
              <button
                type="submit"
                className="px-4 w-fit mx-auto border border-gray-400 shadow-lg font-semibold rounded-lg py-2 flex justify-center items-center hover:bg-slate-50"
              >
                <GoogleIcon />
                <span className="text-sm">Sign in with Google</span>
              </button>
            </Form>
          </ul>
          <div className="flex items-center justify-center gap-2 pt-6">
            <span className="text-muted-foreground">Belum memiliki akun?</span>
            <Link
              className="text-[#9D21E6]"
              to={redirectTo ? `/register?${encodeURIComponent(redirectTo)}` : "/register"}
            >
              Daftar
            </Link>
          </div>

          <div className="text-center font-medium text-sm mt-10">
            <p className="text-[#9D21E6]">Dibuat oleh</p>
            <p className="text-[#686464]">PT. KELAS DUNIA EKASAKTI</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export const meta: MetaFunction = () => {
  return [{ title: "Login to Kelas Digital" }];
};

export function ErrorBoundary() {
  return <GeneralErrorBoundary />;
}

export const GoogleIcon = () => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth={0}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 48 48"
      enableBackground="new 0 0 48 48"
      className="inline-block mr-2 h-5 lg:h-6 w-5 lg:w-6 bg-white rounded-full ring-2 ring-white"
      height="24px"
      width="24px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#FFC107"
        d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12
	c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24
	c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
      />
      <path
        fill="#FF3D00"
        d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657
	C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
      />
      <path
        fill="#4CAF50"
        d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36
	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
      />
      <path
        fill="#1976D2"
        d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571
	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
      />
    </svg>
  );
};
